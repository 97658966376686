<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";
import Multiselect from "vue-multiselect";

export default {
  page: {
    title: "Code Promo",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      title: "Code Promo",
      tmpCoverFile: null,
      file: null,
      fileModif: null,
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Code Promo",
          active: true,
        },
      ],
      moyensTransport: ["MOTO", "VOITURE", "TAXI", "BUS"],
      typesDistance: ["COURT", "MOYEN", "LONG"],
      experience: ["R-Débutant", "R-Qualifié", "R-Ambassadeur"],
      codePromoForm: {
        code: "",
        reduction: 0,
        dateLimite: new Date().toLocaleDateString(),
        birthday: false,
        moyensTransport: [],
        typesDistance: [],
        experience: [],
      },
      codePromoFormModif: {
        id: 0,
        code: "",
        reduction: 0,
        dateLimite: "",
        birthday: false,
        moyensTransport: "",
        typesDistance: "",
        experience: "",
      },
      codePromoRowInfo: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      codePromo: [],
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "reduction", sortable: true },
        { key: "dateLimite", sortable: true, label: "Limite" },
        { key: "moyensTransport", sortable: true, label: "Transport" },
        { key: "typesDistance", sortable: true, label: "Distance" },
        { key: "experience", sortable: true },
        { key: "birthday", sortable: true, label: "Annif" },
        { key: "admin", sortable: true, label: "Publié par" },
        { key: "dateCreation", sortable: true },

        { key: "action" },
      ],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      showDelete: false,
      showEdit: false,
      showdisable: false,
      showactive: false,
      erreur: false,
    };
  },
  validations: {},

  mounted() {
    this.init();
  },
  methods: {
    async init() {
      const listCodePromo = await apiRequest(
        "GET",
        "codepromo",
        undefined,
        false
      );

      if (listCodePromo && listCodePromo.data) {
        console.log("listCodePromo:", listCodePromo.data);
        const codePromoFormatTable = listCodePromo.data.promo.map((promo) => {
          return {
            code: promo.code,
            reduction: promo.reduction+" %",
            dateCreation: new Date(promo.dateCreation).toLocaleString("fr-FR"),
            dateLimite: new Date(promo.dateLimite).toLocaleString("fr-FR"),
            birthday: promo.birthday,
            actif: promo.actif,
            idAdmin: promo.idAdmin,
            moyensTransport: promo.moyensTransport,
            typesDistance: promo.typesDistance,
            experience: promo.experience,

            admin: promo.admin.firstname + " " + promo.admin.lastname,
          };
        });
        this.codePromo = codePromoFormatTable;
        /*this.codePromoDes = [];
        this.codePromoActif = [];

        for (let i = 0; i < this.codePromo.length; i++) {
          if (this.codePromo[i].visible == true) {
            this.codePromoActif.push(this.codePromo[i]);
          } else {
            this.codePromoDes.push(this.codePromo[i]);
          }
        }*/
      }
    },
    editRow(row) {
      this.showEdit = true;
      this.codePromoFormModif.titre = row.titre;
      this.codePromoFormModif.url_codePromo = row.url;
      this.codePromoFormModif.soustitre = row.soustitre;
      this.codePromoFormModif.id = row.id;
      this.codePromoFormModif.visible = row.visible;
    },
    desactiverRow(row) {
      this.showdisable = true;
      this.codePromoRowInfo = row;
    },
    activerpromo(row) {
      this.showactive = true;
      this.codePromoRowInfo = row;
    },
    deleteRow(row) {
      this.showDelete = true;
      this.codePromoRowInfo = row;
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      //console.log("this.$v:", this.$v);
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      //this.create();
    },

    tooltipForm() {
      this.submitform = true;
      //this.submitted = true;
      this.formSubmit();
      this.$v.$touch();
    },

    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    handleFileUploadModif() {
      this.fileModif = this.$refs.files.files[0];
    },

    async submitcodePromoForm() {
      if (
        this.codePromoForm.reduction != 0 &&
        this.codePromoForm.dateLimite != "" &&
        this.codePromoForm.moyensTransport.length > 0 &&
        this.codePromoForm.typesDistance.length > 0 &&
        this.codePromoForm.experience.length > 0
      ) {
        var moyensT = "";
        var typesD = "";
        var exp = "";

        this.codePromoForm.moyensTransport.forEach((element) => {
          moyensT += element + ",";
        });

        this.codePromoForm.typesDistance.forEach((element) => {
          typesD += element + ",";
        });

        this.codePromoForm.experience.forEach((element) => {
          exp += element + ",";
        });

        /*console.log("moyensT:", moyensT);
        console.log("typesD:", typesD);
        console.log("exp:", exp);*/

        const returncodePromo = await apiRequest(
          "POST",
          "codepromo",
          {
            code: this.codePromoForm.code,
            reduction: this.codePromoForm.reduction,
            dateLimite: this.codePromoForm.dateLimite,
            birthday: this.codePromoForm.birthday,
            moyensTransport: moyensT,
            typesDistance: typesD,
            experience: exp,
          },
          false
        );

        console.log("codePromo:", returncodePromo);
        if (returncodePromo && returncodePromo.data) {
          console.log("codePromo:", returncodePromo.data);
          this.init();
        }

      } else {
        console.log("VIDE");
        console.log('new Date(this.codePromoForm.dateLimite):', new Date(this.codePromoForm.dateLimite))
        this.erreur = true;
      }
      /**/
    },
    async submitcodePromoFormModif() {
      console.log("this.file:", this.fileModif);
      let formData = new FormData();
      formData.append("id", this.codePromoFormModif.id);
      formData.append("titre", this.codePromoFormModif.titre);
      formData.append("sousTitre", this.codePromoFormModif.soustitre);
      formData.append("codePromoLink", this.codePromoFormModif.url_codePromo);
      formData.append("file", this.files);
      formData.append("visible", this.codePromoFormModif.visible);

      console.log("formData:", formData);

      const returncodePromo = await apiRequest(
        "PUT",
        "codePromo",
        formData,
        true
      );

      //console.log("codePromo:", returncodePromo);
      if (returncodePromo && returncodePromo.data) {
        //console.log("codePromo:", returncodePromo.data);
        this.init();
      }
    },
    async desactivepromo() {
      this.showdisable = false;
      const returncodePromo = await apiRequest(
        "POST",
        "codePromo-off",
        { id: this.codePromoRowInfo.id, visible: false },
        false
      );

      //console.log("codePromo:", returncodePromo);
      if (returncodePromo && returncodePromo.data) {
        //console.log("codePromo:", returncodePromo.data);
        this.init();
      }
    },
    async activepromo() {
      this.showactive = false;
      const returncodePromo = await apiRequest(
        "POST",
        "codePromo-off",
        { id: this.codePromoRowInfo.id, visible: true },
        false
      );

      //console.log("codePromo:", returncodePromo);
      if (returncodePromo && returncodePromo.data) {
        //console.log("codePromo:", returncodePromo.data);
        this.init();
      }
    },
    async deletepromo() {
      this.showDelete = false;
      const returncodePromo = await apiRequest(
        "PUT",
        "codepromo",
        { id: this.codePromoRowInfo.id, visible: false },
        false
      );

      //console.log("codePromo:", returncodePromo);
      if (returncodePromo && returncodePromo.data) {
        //console.log("codePromo:", returncodePromo.data);
        this.init();
      }
    },
    /**
     * Range validation form submit
     */
    // eslint-disable-next-line no-unused-vars
    rangeform(e) {
      this.submit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    typeForm(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="submitcodePromoForm">
              <div class="form-group">
                <label for="Code">Code</label>
                <input
                  type="text"
                  id="Code"
                  class="form-control"
                  placeholder="Code"
                  v-model="codePromoForm.code"
                />
              </div>
              <div class="form-group">
                <label for="">Réduction*</label>
                <input
                  type="number"
                  id=""
                  class="form-control"
                  placeholder="Réduction"
                  v-model="codePromoForm.reduction"
                />
              </div>
              <div class="form-group">
                <label for="">Date Limite*</label>
                <b-form-input
                  id="date-time"
                  type="date"
                  v-model="codePromoForm.dateLimite"
                ></b-form-input>
              </div>
              <div class="form-group">
                <label for="">Moyens Transports*</label>
                <multiselect
                  v-model="codePromoForm.moyensTransport"
                  :options="moyensTransport"
                  :multiple="true"
                  :close-on-select="true"
                ></multiselect>
              </div>
              <div class="form-group">
                <label for="">Types distances*</label>
                <multiselect
                  v-model="codePromoForm.typesDistance"
                  :options="typesDistance"
                  :multiple="true"
                  :close-on-select="true"
                ></multiselect>
              </div>
              <div class="form-group">
                <label for="">Expérience*</label>
                <multiselect
                  v-model="codePromoForm.experience"
                  :options="experience"
                  :multiple="true"
                  :close-on-select="true"
                ></multiselect>
              </div>
              <div class="form-group">
                <span>Anniversaire</span>
                <input
                  type="checkbox"
                  class="ml-2"
                  value="true"
                  v-model="codePromoForm.birthday"
                />
              </div>
              <div class="form-group">
                <button class="btn btn-primary float-right">
                  Ajouter
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <h5>Gérer</h5>
            </div>
            <form>
              <b-tabs nav-class="nav-tabs-custom">
                <b-tab title-link-class="p-3">
                  <template v-slot:title>
                    <a class="font-weight-bold active">Liste des codes promo</a>
                  </template>
                  <div class="row mt-4">
                    <div class="col-sm-12 col-md-6">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          Afficher&nbsp;
                          <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                          ></b-form-select
                          >&nbsp;entrées
                        </label>
                      </div>
                    </div>
                    <!-- Search -->
                    <div class="col-sm-12 col-md-6">
                      <div
                        id="tickets-table_filter"
                        class="dataTables_filter text-md-right"
                      >
                        <label class="d-inline-flex align-items-center">
                          Rechercher:
                          <b-form-input
                            v-model="filter"
                            type="search"
                            class="form-control form-control-sm ml-2"
                          ></b-form-input>
                        </label>
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                  <div class="table-responsive">
                    <b-table
                      class="table-centered"
                      :items="codePromo"
                      :fields="fields"
                      responsive="sm"
                      :per-page="perPage"
                      :current-page="currentPage"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      @filtered="onFiltered"
                    >
                      <template v-slot:cell(action)="row">
                        <!--<a
                          href="javascript:void(0);"
                          class=" text-primary"
                          v-b-tooltip.hover
                          @click="editRow(row.item)"
                          title="Editer"
                        >
                          <i class="mdi mdi-pencil font-size-18"></i>
                        </a>

                        <a
                          href="javascript:void(0);"
                          class="text-success"
                          v-b-tooltip.hover
                          @click="desactiverRow(row.item)"
                          title="Désactiver"
                        >
                          <i class="mdi mdi-close font-size-18"></i>
                        </a>-->
                        <a
                          href="javascript:void(0);"
                          class="text-danger"
                          @click="deleteRow(row.item)"
                          v-b-tooltip.hover
                          title="supprimer"
                        >
                          <i class="mdi mdi-trash-can font-size-18"></i>
                        </a>
                      </template>
                    </b-table>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div
                        class="dataTables_paginate paging_simple_numbers float-right"
                      >
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <b-pagination
                            v-model="currentPage"
                            :total-rows="rows"
                            :per-page="perPage"
                          ></b-pagination>
                        </ul>
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                </b-tab>
              </b-tabs>
            </form>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="erreur"
      title="Veuillez remplir toutes les options."
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="info" class="ml-3 mr-4" @click="erreur = false"
          >Ok</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showDelete"
      title="Voulez-vous vraiment supprimer ce code promo ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showDelete = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="deletepromo"
          >Oui</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showdisable"
      title="Voulez-vous vraiment désactiver ce bon promo ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showdisable = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="desactivepromo"
          >Oui</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showactive"
      title="Voulez-vous vraiment activer bon promo ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showactive = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="activepromo"
          >Oui</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-center"
      centered
      size="xl"
      v-model="showEdit"
      title="Editer utilisateur"
      title-class="font-18"
      hide-footer
    >
      <form
        enctype="multipart/form-data"
        @submit.prevent="submitcodePromoFormModif"
      >
        <div class="form-group">
          <label for="tite">Titre</label>
          <input
            type="text"
            id="title"
            class="form-control"
            placeholder="titre"
            v-model="codePromoFormModif.titre"
          />
        </div>
        <div class="form-group">
          <label for="tite">Sous titre</label>
          />
        </div>
        <div class="form-group">
          <label for="tite">texte</label>
          <input
            type="text"
            id="title"
            class="form-control"
            placeholder="sous titre"
            v-model="codePromoFormModif.soustitre"
          />
        </div>
        <div class="form-group">
          <label for="tite">Lien du bon promo</label>
          <input
            type="url"
            id="title"
            class="form-control"
            placeholder="lien du bon promo"
            v-model="codePromoFormModif.url_codePromo"
          />
        </div>
        <div class="form-group">
          <label for="title">Image</label>
          <input
            type="file"
            id="files"
            ref="files"
            v-on:change="handleFileUploadModif"
            class="form-control"
          />
        </div>
        <div class="form-group">
          <button class="btn btn-primary float-right">Modifier</button>
          placeholder="titre" />
        </div>
        <div class="form-group">
          <label for="tite">image</label>
          <input type="file" id="title" class="form-control" />
        </div>
        <div class="form-group">
          <button class="btn btn-primary float-right">Ajouter</button>
        </div>
      </form>
    </b-modal>
  </Layout>
</template>
